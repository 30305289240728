<template>
    <div class="mt-n3">
        <aswitch
                :fieldAttributes="{ ...fieldAttributes, 'hide-details': true }"
                :fieldAttrInput="fieldAttributes.fieldAttrInput"
                :value="localValue"
                :templateContent="result"
                @input="updateValue"
        ></aswitch>
    </div>
</template>
<script>
  import aswitch from "@/commonComponents/aswitch.vue";
  export default {
    components: { aswitch },
    props: ["result", "fieldAttributes", "value"],
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit("input", newValue);
        },
      },
    },
    methods: {
      updateValue(value) {
        this.$emit("input",value);
      },
    },
  };
</script>